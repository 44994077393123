import { v4 as uuid } from "uuid";
import { fields } from "./fields";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";
import { mask } from "@monorepo/informationSecurity/src/views/ExternalAPIUsers/constants/mask";
import { ModalType } from "@monorepo/utils/src/variables/modalType";

export const systemInfoElements: IModalElementType[] = [
  {
    id: uuid(),
    isEdited: true,
    className: "info-modal__element_title",
    title: "Логин",
    tooltip: "Логин для входа в Систему",
    value: fields.USER_LOGIN,
    isRequired: true,
    mask: mask.maskLogin,
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Секретный ключ",
    className: "info-modal__element_title",
    tooltip: "Секретный ключ",
    value: fields.USER_PASSWORD,
    modalType: ModalType.NEW,
    minLength: "6",
    fieldType: "password",
  },
  {
    id: uuid(),
    title: "Роли",
    className: "info-modal__element_title",
    tooltip: "Роли пользователя",
    value: fields.USER_ROLES,
    slotName: "selectRoles",
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Описание",
    className: "info-modal__element_full-size info-modal__element_textarea info-modal__annotation",
    tooltip: "Описание",
    value: fields.USER_DESCRIPTION,
    isRequired: true,
    maxLength: "20000",
    fieldType: "textarea",
  },
];

export const modalElements: IModalElementType[] = [
  {
    id: uuid(),
    isEdited: false,
    title: "Описание",
    className: "info-modal__element",
    tooltip: "Описание",
    value: fields.USER_DESCRIPTION,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Пароль",
    className: "info-modal__element",
    tooltip: "Пароль для входа в Систему",
    value: fields.USER_PASSWORD,
    fieldType: "password",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Роли",
    className: "info-modal__element",
    tooltip: "Роли пользователя",
    value: fields.USER_ROLES,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Фонд",
    className: "info-modal__element",
    tooltip: "Фонд",
    value: fields.USER_FUND,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Статус",
    className: "info-modal__element",
    tooltip: "Может принимать следующие значения: Активен, Удалён, Заблокирован",
    value: fields.USER_STATUS,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Заблокирован до",
    className: "info-modal__element",
    tooltip: "Дата, до которой заблокирована учётная запись пользователя",
    value: fields.BLOCKED_UNTIL,
  },
];

export const techElements: IModalElementType[] = [
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор",
    className: "info-modal__element_title",
    tooltip: "Идентификатор пользователя",
    value: fields.USER_ID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Пользователь, сохранивший запись",
    className: "info-modal__element_title",
    tooltip: "Пользователь, сохранивший запись",
    value: fields.USER_PARENT,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата сохранения",
    className: "info-modal__element",
    tooltip: "Дата сохранения записи",
    value: fields.USER_SAVE_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Пользователь, изменивший запись",
    className: "info-modal__element_title",
    tooltip: "Пользователь, изменивший запись",
    value: fields.USER_EDIT_USER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата последнего обновления",
    className: "info-modal__element",
    tooltip: "Дата последнего обновления",
    value: fields.USER_EDIT_DATE,
  },
];

export const fullModalElements: IModalElementType[] = [
  ...systemInfoElements,
  ...modalElements,
  ...techElements,
  {
    id: uuid(),
    title: "Редактирование / создание пользователя",
    className: "info-modal__element_title",
    value: "canEdit",
  },
  {
    id: uuid(),
    title: "Удаление пользователя",
    className: "info-modal__element_title",
    value: "canDelete",
  },
];
