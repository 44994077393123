






































































































import { defineComponent, ref } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/externalApi/viewTitle";
import {
  fullModalElements as modalElements,
  systemInfoElements,
  techElements,
} from "@monorepo/utils/src/variables/projectsData/externalApi/modalElements";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import moment from "moment";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { fields } from "@monorepo/utils/src/variables/projectsData/externalApi/fields";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import SelectRolesCard from "@monorepo/uikit/src/components/common/Select/SelectRolesCard.vue";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { IModel } from "@monorepo/informationSecurity/src/views/ExternalAPIUsers/types/IModel";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import useCheckChangesModal from "@monorepo/utils/src/composables/useCheckChangesModal";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";
import { convertSaveCardObject } from "@monorepo/informationSecurity/src/views/ExternalAPIUsers/utils/convertSaveCardObject";
import AccessesTree from "@monorepo/uikit/src/components/BaseUserModal/AccessesTree.vue";
import Vue from "vue";

const defaultObject = () => ({} as Partial<IModel>);

//Type '{  fund?: { code: string; } | undefined;  ; id?: number | undefined; }' is not assignable to type 'Partial<IModel>'.Vetur(2322)

export default defineComponent({
  name: "UsersListInfoModal",
  components: {
    CardModalInfoElement,
    FormInputElement,
    SelectRolesCard,
    CardModalToggleTableItems,
    CardModalToggleViewSize,
    CardModalStatusChip,
    CardModalChapter,
    AccessesTree,
  },
  props: {
    type: {
      type: String,
      default: ModalType.NEW,
    },
  },
  data() {
    return {
      viewUniqKey,
      fields,
      zIndexSelectCard: 201,
      section: Sections.USERS_LIST,
      openedPanels: [0],
      modalElements,
      cardMode: CardMode,
      isLoading: false,
      ModalType,
      systemInfoElements,
      techElements,
    };
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          addQueryOpenedId: () => void;
          handleItem: (value: string) => Promise<IModel>;
          formValues: IModel;
          isShowAccess: boolean;
          oiks: Record<string, unknown>[];
          accessValues: Record<string, Record<string, any>>;
          getUserAccesses: (payload: { userId: string }) => Promise<Record<string, string[]>>;
          key: string;
          userDefaultData: IModel;
          getResultSaveData: (data: Record<string, unknown>) => IModel;
          changedFormValues: Record<string, unknown>;
          isLoading: boolean;
          isShowAnimation: boolean;
        },
        value: string
      ) {
        if (value) {
          this.isLoading = true;
          await this.addQueryOpenedId();
          if (this.isShowAccess) {
            const accessValuesObj = await this.getUserAccesses({ userId: value });
            const oikObj = this.oiks.reduce((res: Record<string, Record<string, unknown>>, item: Record<string, unknown>) => {
              res[item.id as string] = item;
              return res;
            }, {} as Record<string, Record<string, unknown>>);
            const oiksResult = ((accessValuesObj?.oikIds as string[]) || []).map((oikId: string) => oikObj[oikId]).filter((item) => item) as Record<
              string,
              unknown
            >[];
            this.accessValues = {
              fundIds: ((accessValuesObj?.fundIds as string[]) || []).reduce((res, item) => {
                res[item] = true;
                return res;
              }, {} as Record<string, boolean>),
              archiveIds: ((accessValuesObj?.archiveIds as string[]) || []).reduce((res, item) => {
                res[item] = true;
                return res;
              }, {} as Record<string, boolean>),
              oikIds: oiksResult,
              additionalArchiveIds: {},
            };
          }
          this.handleItem(value);
        } else {
          this.changedFormValues = this.getResultSaveData(defaultObject());
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "cardModeList", "isShowAnimation"]),
    ...mapGetters("externalAPIUsers", { openedId: "openedUserId" }),
    ...mapGetters("externalAPIUsers", ["currentRoles", "defaultTwoStepsAuth", "infiniteId", "oiks"]),
    status(): string {
      if (this.type === ModalType.NEW) {
        return "";
      }
      return this.formValues.active ? "Активный" : "Заблокирован";
    },
    statusColor(): string {
      const status: string = this.status as string;
      switch (status) {
        case "Активный":
          return "#00A459";
        case "Заблокирован":
          return "#D34039";
        default:
          return "";
      }
    },
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    modalFieldTitle(): (field: string) => IModalElementType {
      return (field: string) => {
        return this.modalElements.find((item: IModalElementType) => item.value === field) || ({} as IModalElementType);
      };
    },
    title(this: { formValues: { username: string }; type: ModalType }): string {
      switch (this.type) {
        case ModalType.EDIT:
        case ModalType.READ:
          return "Карточка пользователя";
        case ModalType.NEW:
        default:
          return "Новый пользователь";
      }
    },
    isShowTechData(): boolean {
      return isAuthorityExist(this.user, authorities.TECH_INFO);
    },
    isShowAccess(): boolean {
      return isAuthorityExist(this.user, authorities.ARCHIVE_LIST);
    },
  },
  methods: {
    ...mapActions("app", ["sendChangePasswordEmail"]),
    ...mapActions("externalAPIUsers", {
      changeOpenedId: "changeOpenedUserId",
    }),
    ...mapActions("externalAPIUsers", [
      "getUserCardElement",
      "saveCard",
      "refreshScroll",
      "deleteElement",
      "resetPassword",
      "blockUser",
      "unblockUser",
      "getUserAccesses",
      "setUserAccesses",
      "getArchiveHierarchy",
    ]),
    handleItem(value: number) {
      this.getUserCardElement(value)
        .then((data: IModel) => {
          this.formValues = {
            ...data,
            createDate: data?.createDate ? moment(data?.createDate).format("YYYY-MM-DD") : "",
            updateDate: data?.updateDate ? moment(data?.updateDate).format("YYYY-MM-DD") : "",
          };
          this.changedFormValues = this.getResultSaveData(this.formValues);
        })
        .catch(console.error)
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleToggleUserActive() {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: this.toggleUserActive,
          yesBtnTitle: this.formValues.active ? "Да, заблокировать" : "Да, разблокировать",
          title: this.formValues.active ? "Заблокировать учетную запись пользователя?" : "Разблокировать учетную запись пользователя?",
        })
      );
    },
    getResultSaveData(item: Partial<IModel>) {
      return {
        ...(item.id ? { id: item.id } : {}),
        identityName: item.identityName,
        name: item.name,
        roles: (item.roles || []).map((role: { id: string }) => ({ id: role.id })),
        active: item.active ?? true,
      };
    },
    async saveUserAccesses(id?: number) {
      if (!id) {
        return;
      }
      await this.setUserAccesses({
        externalSystemId: id,
        fundIds: Object.entries(this.accessValues?.fundIds || {})
          .filter(([, value]) => value)
          .map(([key]) => key),
        archiveIds: Object.entries({ ...(this.accessValues?.archiveIds || {}), ...(this.accessValues?.additionalArchiveIds || {}) })
          .filter(([, value]) => value)
          .map(([key]) => key),
        oikIds: ((this.accessValues?.oikIds as any[]) || []).map((oik) => oik.id as string),
      });
    },
    async onSave(data: IModel) {
      let resultData = {
        ...data,
        id: data.id || this.formValues.id || 0,
      };

      const savedData = await this.saveCard(this.getResultSaveData(resultData));
      if (savedData) {
        if (this.isShowAccess) {
          await this.saveUserAccesses(this.formValues.id || savedData.data?.id);
        }
        this.changedFormValues = this.getResultSaveData(resultData);
        this.refreshScroll();
        if (this.type !== ModalType.NEW) {
          this.closeModal({}, true);
        } else {
          Vue.set(this.formValues, fields.USER_PASSWORD, savedData.data?.secretKey);
        }
      }
    },
    async toggleUserActive() {
      let resultData = {
        ...this.formValues,
        active: !this.formValues.active,
      };
      const isSaved = await this.saveCard(this.getResultSaveData(resultData));
      if (isSaved) {
        this.changedFormValues = this.getResultSaveData(resultData);
        this.refreshScroll();
        this.handleItem(this.formValues?.id ?? 0);
      }
    },
    closeModal(event: Record<string, unknown>, isForse = false): void {
      if (this.type !== ModalType.READ && !isForse) {
        const isClose = (this as unknown as { checkChangesBeforeClose: () => boolean }).checkChangesBeforeClose();
        if (isClose) {
          return void this.$emit("close");
        }
        return;
      }
      this.$emit("close");
    },
  },
  setup(props, context) {
    const { root } = context;
    root.$store.dispatch("externalAPIUsers/getCurrentRolesList");
    const formValues = ref({} as Partial<IModel>);
    const changedFormValues = ref({});
    const accessValues = ref({ fundIds: {}, archiveIds: {}, oikIds: [], additionalArchiveIds: {} });
    const { checkChangesBeforeClose, checkChangesBeforeSave } = useCheckChangesModal(context, formValues, changedFormValues, convertSaveCardObject);
    useModalChangeByKeypress(root, "externalAPIUsers", Sections.USERS_LIST, props.type, "changeOpenedUserId");
    const { addQueryOpenedId } = useUniqueLinkModal(root, "externalAPIUsers", "openedUserId");

    return {
      formValues,
      changedFormValues,
      accessValues,
      addQueryOpenedId,
      checkChangesBeforeClose,
      checkChangesBeforeSave,
    };
  },
});
