











































import { defineComponent, ref } from "@vue/composition-api";
import Autocomplete from "@monorepo/uikit/src/components/tableViews/Autocomplete.vue";
import { mapActions, mapGetters } from "vuex";
import FilterLabel from "@monorepo/uikit/src/components/tableViews/FilterLabel.vue";
import FindButton from "@monorepo/uikit/src/components/tableViews/FindButton.vue";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import ToggleFilters from "@monorepo/uikit/src/components/tableViews/ToggleFilters.vue";
import ClearFilters from "@monorepo/uikit/src/components/tableViews/ClearFilters.vue";
import { defaultFilterPlaceholder } from "@monorepo/utils/src/constants";
import { fields } from "@monorepo/utils/src/variables/projectsData/externalApi/fields";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/externalApi/filtersElements";
import { currentFilters as setCurrentFilters } from "../../utils/defaultCurrentFilters";
import useTemplateFilters from "@monorepo/utils/src/composables/useTemplateFilters";
import useFilter from "@monorepo/utils/src/composables/useFilter";
import useAutocomplete from "@monorepo/utils/src/composables/useAutocomplete";

const autocompleteFiltersKeys = [fields.FILTER_LOGIN, fields.FILTER_FUND];

export default defineComponent({
  name: "UserFilters",
  components: {
    ClearFilters,
    ToggleFilters,
    FindButton,
    FilterLabel,
    Autocomplete,
    SelectFilter,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultFilterPlaceholder,
      fields,
      filtersElements,
      autocompleteFiltersKeys,
      selectTop: 30,
    };
  },
  computed: {
    ...mapGetters("externalAPIUsers", ["fieldFilters", "isTableLoading", "funds", "searchTemplates"]),
    fundNames(): { title: string; value: string; isDeleted: boolean }[] {
      return this.funds.map((fund: { name: string; code: string; isDeleted: boolean }) => ({
        title: fund.name,
        value: fund.code,
        isDeleted: fund.isDeleted,
      }));
    },
  },
  methods: {
    ...mapActions("externalAPIUsers", ["addFilter", "refreshScroll", "clearFilters", "getAutocompleteByLogin", "saveSearchTemplates"]),
  },
  setup(props, context) {
    const { root } = context;
    const currentFilters = ref((setCurrentFilters as () => Record<string, unknown>)());
    const notHiddenKeys = ref([] as string[]);
    const isShowToggle = ref(false);
    const autocompleteItemsResultLists = ref({} as { [key: string]: Record<string, unknown>[] });
    const isLoadingAutocomplete = ref(false);
    useTemplateFilters(root, "externalAPIUsers", currentFilters);
    const { filter, hiddenRowsStyleObj, openHiddenFilterOnStart, toggleOpenFilter, clearFiltersCb, refresh, updateFiltersDivHeight } = useFilter(
      context,
      props as {
        value: boolean;
      },
      "externalAPIUsers",
      {},
      currentFilters,
      setCurrentFilters,
      filtersElements as { value: string; title: string }[],
      notHiddenKeys,
      isShowToggle
    );
    const { getAutocompleteItems } = useAutocomplete(
      root,
      "externalAPIUsers",
      currentFilters,
      autocompleteItemsResultLists,
      isLoadingAutocomplete,
      autocompleteFiltersKeys as string[]
    );

    return {
      filter,
      hiddenRowsStyleObj,
      openHiddenFilterOnStart,
      toggleOpenFilter,
      clearFiltersCb,
      refresh,
      updateFiltersDivHeight,
      currentFilters,
      notHiddenKeys,
      isShowToggle,
      getAutocompleteItems,
      autocompleteItemsResultLists,
      isLoadingAutocomplete,
    };
  },
});
