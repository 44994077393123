import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/externalApi/fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Идентификатор",
    defaultWidth: 150,
    tooltip: "Идентификатор пользователя",
    value: fields.USER_ID,
    isSorted: true,
    isHandle: true,
    isHiddenByDefault: true,
    id: uuid(),
  },
  {
    text: "Логин",
    defaultWidth: 250,
    tooltip: "Логин для входа в Систему",
    value: fields.USER_LOGIN,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Описание",
    defaultWidth: 300,
    tooltip: "описание",
    value: fields.USER_DESCRIPTION,
    isLink: false,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Архивы/фонды",
    tooltip: "Архивы/фонды",
    defaultWidth: 200,
    value: fields.USER_FUND,
    //isLink: true,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
];
