export interface IUserElement {
  USER_ID: string;
  USER_LOGIN: string;
  USER_PASSWORD: string;
  USER_FULL_NAME: string;
  USER_POSITION: string;
  USER_DIVISION: string;
  USER_PHONE: string;
  USER_EMAIL: string;
  USER_ROLES: string;
  USER_ROLES_ID: string;
  USER_PARENT: string;
  USER_SAVE_DATE: string;
  USER_EDIT_DATE: string;
  USER_EDIT_USER: string;
  USER_STATUS: string;
  BLOCKED_UNTIL: string;
  USER_IS_DELETED: string;
  USER_IS_BLOCKED: string;
  LAST_LOGIN_TIME: string;
  LAST_LOGIN_IP: string;
  USER_IS_ACTIVE: string;
  USER_DELETED: string;
  TWO_FACTORS_AUTH: string;

  USER_DESCRIPTION: string;
  USER_FUND: string;
  FILTER_LOGIN: string;
  FILTER_FUND: string;
}

export const fields: IUserElement = {
  USER_PASSWORD: "secretKey",
  USER_FULL_NAME: "fullName",
  USER_POSITION: "position",
  USER_DIVISION: "department",
  USER_PHONE: "phone",
  USER_EMAIL: "email",
  USER_ROLES: "roles",
  USER_ROLES_ID: "rolesId",
  USER_PARENT: "createUser",
  USER_EDIT_USER: "updateUser",
  USER_SAVE_DATE: "createDate",
  USER_EDIT_DATE: "updateDate",
  USER_STATUS: "status",
  USER_IS_DELETED: "userIsDeleted",
  USER_IS_BLOCKED: "userIsBlocked",
  BLOCKED_UNTIL: "blockedUntil",
  LAST_LOGIN_TIME: "lastLoginTime",
  LAST_LOGIN_IP: "lastLoginIp",
  USER_IS_ACTIVE: "active",
  USER_DELETED: "deleted",
  TWO_FACTORS_AUTH: "enable2FA",

  USER_ID: "id",
  USER_LOGIN: "identityName",
  USER_DESCRIPTION: "name",
  USER_FUND: "fund",
  FILTER_LOGIN: "login",
  FILTER_FUND: "fund",
};
