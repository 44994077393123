import { fields } from "./fields";

export const filtersElements = [
  {
    value: fields.FILTER_LOGIN,
    title: "Логин",
  },
  {
    value: fields.FILTER_FUND,
    title: "Фонд",
  },
];
