
























import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters } from "vuex";
import { getPluralNoun } from "@monorepo/utils/src/utils/pluralNoun";
import eventBus from "@monorepo/utils/src/eventBus/index";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";
import { IUser } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IUser";
import { IUserListCardElement } from "@monorepo/utils/src/types/IUserListCardElement";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { DELETE_EVENTS_ACTIONS } from "@monorepo/utils/src/eventBus/events/deleteEvents";
import { IModel } from "@monorepo/informationSecurity/src/views/ExternalAPIUsers/types/IModel";

export default defineComponent({
  name: "Footer",
  components: {
    ExportFooterButton,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("externalAPIUsers", ["isSelectAll", "selectedIds", "totalUsers", "users"]),
    selectedLength(): number {
      return this.isSelectAll ? this.totalUsers : Object.values(this.selectedIds).filter(Boolean).length;
    },
    selectedLengthTitle(): string {
      return this.getPluralUserTitle(this.selectedLength);
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.USER_EXPORT);
    },
    isShowDeleteBtn(): boolean {
      const isRoleAccess = isAuthorityExist(this.user, authorities.USER_DELETE);

      const mustBeDeletedUsers = this.getMustBeDeletedUsers();
      const mustBeRestoredUsers = this.getMustBeRestoredUsers();

      if (!isRoleAccess) {
        return false;
      }
      return !this.isSelectAll && !!mustBeDeletedUsers.length && !mustBeRestoredUsers.length;
    },
    isShowRestoreBtn(): boolean {
      const isRoleAccess = isAuthorityExist(this.user, authorities.USER_DELETE);

      const mustBeDeletedUsers = this.getMustBeDeletedUsers();
      const mustBeRestoredUsers = this.getMustBeRestoredUsers();

      if (!isRoleAccess) {
        return false;
      }
      return !this.isSelectAll && !mustBeDeletedUsers.length && !!mustBeRestoredUsers.length;
    },
  },
  methods: {
    ...mapActions("externalAPIUsers", ["setSelectedIds", "getExportUsers"]),
    ...mapActions("externalAPIUsers", ["refreshScroll", "saveCard"]),
    async deleteElementCb() {
      eventBus.$emit(DELETE_EVENTS_ACTIONS.DELETE);
      const mustBeDeletedUsers = this.getMustBeDeletedUsers();
      const promises = [];

      for (const user of mustBeDeletedUsers) {
        let resultData = {
          ...user,
          active: false,
        };
        promises.push(this.saveCard(this.getResultSaveData(resultData)));
      }

      Promise.all(promises).then((data) => {
        const isDeleted = data.find((isDeleted: boolean) => isDeleted);
        if (isDeleted) {
          setTimeout(() => {
            this.setSelectedIds({});
            this.refreshScroll();
          }, 800);
        }
      });
    },
    async restoreElementCb() {
      const mustBeRestoredUsers = this.getMustBeRestoredUsers();
      const promises = [];

      for (const user of mustBeRestoredUsers) {
        let resultData = {
          ...user,
          active: true,
        };
        promises.push(this.saveCard(this.getResultSaveData(resultData)));
      }

      Promise.all(promises).then((data) => {
        const isRestored = data.find((isRestored: boolean) => isRestored);
        if (isRestored) {
          this.setSelectedIds({});
          this.refreshScroll();
        }
      });
    },
    getMustBeDeletedUsers() {
      return (this.users || []).filter((user: IUserListCardElement) => !!this.selectedIds?.[user.id] && !!user?.active);
    },
    getMustBeRestoredUsers() {
      return (this.users || []).filter((user: IUserListCardElement) => !!this.selectedIds?.[user.id] && !user?.active);
    },
    getPluralUserTitle(length: number) {
      return getPluralNoun(length, "Пользователь", "Пользователя", "Пользователей");
    },
    getResultSaveData(item: Partial<IModel>) {
      return {
        ...(item.id ? { id: item.id } : {}),
        identityName: item.identityName,
        name: item.name,
        roles: (item.roles || []).map((role: { id: string }) => ({ id: role.id })),
        ...(item.fund ? { fund: { code: item.fund.code } } : {}),
        active: item.active ?? true,
      };
    },
    openModal() {
      const mustBeDeletedUsers = this.getMustBeDeletedUsers();
      let title = "";

      if (mustBeDeletedUsers.length > 1) {
        title = `Заблокировать ${mustBeDeletedUsers.length} ${this.getPluralUserTitle(mustBeDeletedUsers.length)}?`;
      } else {
        const key = Object.keys(this.selectedIds).filter((key) => this.selectedIds[key])[0];
        const element = this.users.find((el: IUser) => el.id == key);
        title = `Заблокировать пользователя «${element.identityName || ""}»?`;
      }

      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", { cbOnDelete: this.deleteElementCb, title, yesBtnTitle: "Да, заблокировать" })
      );
    },
    openRestoreModal() {
      const mustBeRestoredUsers = this.getMustBeRestoredUsers();
      let title = "";

      if (mustBeRestoredUsers.length > 1) {
        title = `Разблокировать ${mustBeRestoredUsers.length} ${this.getPluralUserTitle(mustBeRestoredUsers.length)}?`;
      } else {
        const key = Object.keys(this.selectedIds).filter((key) => this.selectedIds[key])[0];
        const element = this.users.find((el: IUser) => el.id == key);
        title = `Разблокировать пользователя «${element.identityName || ""}»?`;
      }

      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: this.restoreElementCb,
          icon: "mdi-delete-restore",
          yesBtnTitle: "Да, разблокировать",
          title,
        })
      );
    },
  },
});
