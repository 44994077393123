import { IModel } from "@monorepo/informationSecurity/src/views/ExternalAPIUsers/types/IModel";

export const convertSaveCardObject = (item: Partial<IModel>): Partial<IModel> => {
  return {
    ...(item.id ? { id: item.id } : {}),
    identityName: item.identityName,
    name: item.name,
    roles: (item.roles || []).map((role: { id: string }) => ({ id: role.id })),
    active: item.active ?? true,
  };
};
